if (document.getElementById("editCategoryBtn") !== null) {
    document.getElementById("editCategoryBtn").onclick = function() {
        var element = document.getElementsByClassName('editCat');
        for (var index = 0; index < element.length; index++) {
            element[index].style.display = 'block'
        }
    };
}

function changeValues(element) {
    if (document.getElementsByName('groupP') != null) {
        var check = document.getElementsByName('groupP');
        var checkCounter = 0;
        for (var index = 0; index < check.length; index++) {
            if (check[index].checked == true) {
                checkCounter++;

            }
        }

        if (checkCounter > 1) {
            var popup = document.getElementById("myPopup");
            var node = document.createElement("span");
            var textnode = document.createTextNode("Не може да имате повече от един групиращ параметър");
            node.appendChild(textnode);
            popup.querySelector("p[id='message']").appendChild(node);
            popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
            popup.classList.toggle("show");
            popup.style.display = "block";
            element.checked = false;
            checkCounter = 0;
        } else {
            document.getElementById(element.id).onchange = function() {
                var value = this.previousSibling.previousSibling.value;
                this.previousSibling.previousSibling.value = this.value;
                this.value = value
            };
        }
    } else {
        document.getElementById(element.id).onchange = function() {
            var value = this.previousSibling.previousSibling.value;
            this.previousSibling.previousSibling.value = this.value;
            this.value = value
        };
    }

}

function editCategory(categoryId) {
    document.getElementById('name-' + categoryId).style.display = 'block';
    document.getElementById('name-' + categoryId).classList.remove('disabled-field');
    document.getElementById('name-' + categoryId).disabled = false;
    document.getElementById('cat-edit-' + categoryId).style.display = 'block';
}

function edidSubcategories(categoryId) {
    var element = document.getElementsByClassName('editSubCat-' + categoryId);
    for (var index = 0; index < element.length; index++) { element[index].style.display = 'block' }
}

function editSubcategory(subCategoryId) {
    document.getElementById('name-' + subCategoryId).style.display = 'block';
    document.getElementById('name-' + subCategoryId).classList.remove('disabled-field');
    document.getElementById('name-' + subCategoryId).disabled = false;
    document.getElementById('cat-edit-' + subCategoryId).style.display = 'block';
}

// VIEW BUTTON
var elements = document.getElementsByClassName("view-btn");
for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    element.onclick = function() {
        var button_id = element.getAttribute('id');
        var temp = button_id.split('-');
        var id = temp[1];
        var level = temp[0]
        viewCategory(id, level)
    }
}

//DROPDOWN ENTITIES
function createDropdown(level, active, page, id, tempId, name) {
    if (level === 'category' && active === 'yes' && name !== "Сервиз") {
        document.getElementById("createSubcategoryBtn").classList.remove('display-none');
        document.getElementById("orderSubcategoryBtn").classList.remove('display-none');
        document.getElementById("active").selected = true;
    } else if (level === 'category' && active === 'yes' && name === "Сервиз") {
        document.getElementById("active").selected = true;
    } else if (level === 'subcategory' && active === 'yes') {
        document.getElementById("createSubcategoryBtn").classList.add('display-none');
        document.getElementById("orderSubcategoryBtn").classList.add('display-none');
        document.getElementById("active").selected = true;
    } else {
        document.getElementById("createSubcategoryBtn").classList.add('display-none');
        document.getElementById("orderSubcategoryBtn").classList.add('display-none');
        document.getElementById("inactive").selected = true;
    }
    if (page === 'yes') {
        document.getElementById("activePage").selected = true;
        document.getElementById("createPageBtn").classList.remove('display-none');
        document.getElementById("createPageBtn").setAttribute('data-id', id);
        document.getElementById("createPageBtn").setAttribute('data-level', level);
        document.getElementById("createPageBtn").setAttribute('data-tempid', tempId);
    } else {
        document.getElementById("inactivePage").selected = true;
        document.getElementById("createPageBtn").classList.add('display-none');
    }
}

function viewCategory(id, level, tempCatId) {
    var paramForm = document.getElementById("existingparamfields");
    var form = document.getElementById("review");
    var content = document.getElementById("editCatContent");
    form.classList.remove('display-none');

    content.querySelector("input[name=page-level]").value = level;
    content.querySelector("input[name=page-level]").setAttribute('value', level);
    content.querySelector("input[name=category-id]").value = id;
    content.querySelector("input[name=category-id]").setAttribute('value', id);
    destroyParamForm(paramForm);
    var name = null;
    var nameEn = null;
    var page = null;
    var active = null;
    var parentId = null;
    var parentName = null;
    var catId = null;
    var textBg = null;
    var textEn = null;
    var pageUrl = null;
    var photoName = null;
    var photoDesc = null;
    var photoDescEn = null;
    var photoURL = null;

    categoryContent.forEach(contentCategory => {
        if (contentCategory['level'] == level && contentCategory['categoryId'] == id) {
            catId = contentCategory['id'];
            textBg = contentCategory['textBG'];
            textEn = contentCategory['textEN'];
            pageUrl = contentCategory['pageURL'];
            photoName = contentCategory['photoName'];
            photoDesc = contentCategory['photoDescBG'];
            photoDescEn = contentCategory['photoDescEN'];
            photoURL = contentCategory['photoDescURL'];
        }
    });

    if (catId !== null) {
        content.action = '/editCategoryContent/' + catId;
    } else {
        content.action = '/createCategoryContent';
    }

    var editors = document.getElementsByClassName('text-view');
    editors[0].innerHTML = textBg;
    editors[1].innerHTML = textEn;
    content.querySelector('input[name=page-link]').value = pageUrl;
    content.querySelector('input[name=page-link]').setAttribute('value', pageUrl);

    if (level === 'category') {
        for (let index = 0; index < categories.length; index++) {
            if (categories[index]['id'] === parseInt(id)) {
                name = categories[index]['name'];
                nameEn = categories[index]['nameEN'];
                page = categories[index]['page'];
                active = categories[index]['active'];
                break;
            }
        }
        form.action = '/udateCategory/' + id;
        form.querySelector("h4[id=title").innerHTML = "Категория " + name;
        content.querySelector("input[name=page-link]").value = '/' + nameEn.replace(/ /g, "_") + '/';
        content.querySelector("input[name=page-link]").setAttribute('value', '/' + nameEn.replace(/ /g, "_") + '/');
    } else if (level === 'subcategory') {
        for (let index = 0; index < subcategories.length; index++) {
            if (subcategories[index]['id'] === parseInt(id)) {
                name = subcategories[index]['name'];
                nameEn = subcategories[index]['nameEN'];
                page = subcategories[index]['page'];
                active = subcategories[index]['active'];
                parentId = subcategories[index]['categoryId']
                break;
            }
        }

        for (let index = 0; index < categories.length; index++) {
            if (categories[index]['id'] === parentId) {
                parentName = categories[index]['nameEN'];
                break;
            }
        }

        form.action = "/subcategory/" + id;
        form.querySelector("h4[id=title").innerHTML = "Подкатегория " + name;
        content.querySelector("input[name=page-link]").value = '/' + parentName + '/' + nameEn.replace(/ /g, "_") + '/';
    }

    var tempId = null;
    var tempType = null;
    var tempCatId = null;

    for (let indexPage = 0; indexPage < pages.length; indexPage++) {
        if (pages[indexPage]['categoryId'] === parseInt(id) && pages[indexPage]['level'] === level) {
            tempId = pages[indexPage]['id'];
            tempType = pages[indexPage]['type'];
            tempCatId = parseInt(id);
            break;
        }
    }

    form.querySelector("input[name='name[]']").id = "name-{{" + id + " }}";
    form.querySelector("input[name='name[]']").value = name;
    form.querySelector("input[name='nameEN[]']").value = nameEn;
    createDropdown(level, active, page, id, tempId, name);
    document.getElementById("active-indicator").disabled = true;

    if (page === 'yes' && active === 'yes') {
        document.getElementById("templates").classList.remove('display-none');
        content.classList.remove('display-none');

        document.getElementById("addParam").classList.add('display-none');
        if (tempId === null) {
            //console.log(document.querySelector('select[id=page-type]').options.selectedIndex);
            document.querySelector('select[id=page-type]').options.selectedIndex = 0;
            document.getElementById("uncreated").classList.remove('display-none');
            document.getElementById("existing").classList.add('display-none');
            document.getElementById("parameters-form").classList.add('display-none');
            var selected = document.getElementById('selectedElements');
            destroySelected(selected);
        } else {
            document.getElementById("uncreated").classList.add('display-none');
            var templateForm = document.getElementById("existing");
            destroyTemplate(templateForm);
            createTemplate(templateForm, level, tempType, id, interfaceElements);
            createParameters(productParameters, tempId, tempType, paramForm);

            if (tempType === 'product') {
                document.getElementById("parameters-form").classList.remove('display-none');
            } else {
                document.getElementById("parameters-form").classList.add('display-none');
            }
        }
    } else {
        document.getElementById("templates").classList.add('display-none');
        document.getElementById("editCatContent").classList.add('display-none');
        if (tempCatId === id) {
            document.getElementById("uncreated").classList.add('display-none');
            document.getElementById("createPageBtn").setAttribute('data-tempid', template);
        } else {
            document.getElementById("existing").classList.add('display-none');
            document.getElementById("uncreated").classList.add('display-none');
            document.getElementById("createPageBtn").setAttribute('data-tempid', tempId);
        }
    }

    document.getElementById("page-indicator").disabled = true;

    if (name === 'Сервиз') {
        document.getElementById("page-indicator").classList.add('display-none');
        document.querySelector('label[for="page-indicator"]').classList.add('display-none');
    } else {
        document.getElementById("page-indicator").classList.remove('display-none');
        document.querySelector('label[for="page-indicator"]').classList.remove('display-none');
    }
    document.getElementById("dropdown").classList.remove('display-none');
    document.getElementById("template-dropdown").classList.remove('display-none');
    document.getElementById("createSubcategoryBtn").setAttribute("onClick", "javascript: createSubcategory(" + id + ");");
    document.getElementById("orderSubcategoryBtn").setAttribute("onClick", "javascript: initiateDrag(" + id + ");");
}

// EDIT
if (document.getElementById("editBtn") !== null) {
    document.getElementById("editBtn").onclick = function() {
        var form = document.getElementById("review");
        form.querySelector("input[name='name[]']").classList.remove('disabled-field');
        form.querySelector("input[name='name[]']").disabled = false;
        form.querySelector("input[name='nameEN[]']").classList.remove('disabled-field');
        form.querySelector("input[name='nameEN[]']").disabled = false;
        document.getElementById("active-indicator").disabled = false;
        document.getElementById("page-indicator").disabled = false;
        document.getElementById("button-section").classList.remove('display-none');
        deactivateButtons();
    }
}
if (document.getElementById("cancelEdit") !== null) {
    document.getElementById("cancelEdit").onclick = function() {
        var form = document.getElementById("review");
        form.querySelector("input[name='name[]']").classList.add('disabled-field');
        form.querySelector("input[name='name[]']").disabled = true;
        form.querySelector("input[name='nameEN[]']").classList.add('disabled-field');
        form.querySelector("input[name='nameEN[]']").disabled = true;
        document.getElementById("active-indicator").disabled = true;
        document.getElementById("page-indicator").disabled = true;
        document.getElementById("button-section").classList.add('display-none');
        activateButtons();
    };
}

// CATEGORIES
// CREATE CATEGORY
if (document.getElementById("createCategoryBtn") !== null) {
    document.getElementById("createCategoryBtn").onclick = function() {
        document.getElementById('createCat').style.display = 'block';
        document.getElementById("review").classList.add('display-none');
        if (document.getElementById("review-category") !== null) {
            document.getElementById("review-category").classList.add('display-none');
        }
        if (document.getElementById("reviewSubcategory") !== null) {
            document.getElementById("reviewSubcategory").classList.add('display-none');
        }
        if (document.getElementById("templates") !== null) {
            document.getElementById("templates").classList.add('display-none');
            document.getElementById("uncreated").classList.add('display-none');
            document.getElementById("existing").classList.add('display-none');
        }
        deactivateButtons();
    };
}
if (document.getElementById("cancelCreateCat") !== null) {
    document.getElementById("cancelCreateCat").onclick = function() {
        document.getElementById('createCat').style.display = 'none';
        activateButtons();
    };
}
if (document.getElementById("addCategory") !== null) {
    document.getElementById("addCategory").onclick = function() {
        let boxes = document.getElementById("formRow");
        let clone = boxes.firstElementChild.cloneNode(true);
        clone.querySelector("input[type='text']").value = "";
        clone.querySelector("input[type='hidden']").value = "no";
        clone.querySelector("input[type='checkbox']").value = "yes";
        clone.querySelector("input[type='checkbox']").checked = false;
        clone.querySelector("input[type='checkbox']").id = "page" + Math.floor(Math.random() * 100) + 1;
        boxes.appendChild(clone);
    };
}

function removeCategory(element) {
    let parent = element.parentNode.parentNode.parentNode.parentNode;
    let boxes = document.getElementById("formRow");
    if (boxes.childElementCount > 1) {
        parent.remove();
    } else {
        var popup = document.getElementById("myPopup");
        var node = document.createElement("span");
        var textnode = document.createTextNode("Не може да изтриете последния параметър!");
        node.appendChild(textnode);
        popup.querySelector("p[id='message']").appendChild(node);
        popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
        popup.classList.toggle("show");
        popup.style.display = "block";
    }
};

//-------------------------------------------------------------//
// CREATE SUBCATEGORY
function createSubcategory(categoryId) {
    document.getElementById('createSubCat').style.display = 'block';
    document.getElementById('createSubCat').action = '/createSubcategory/' + categoryId;
    document.getElementById('catId').value = categoryId;
    deactivateButtons();
}

if (document.getElementById("cancelCreateSub") !== null) {
    document.getElementById("cancelCreateSub").onclick = function() {
        document.getElementById('createSubCat').style.display = 'none';
        activateButtons();
    };
}

if (document.getElementById("addSubcat") !== null) {
    document.getElementById("addSubcat").onclick = function() {
        let boxes = document.getElementById("formRowSubCat");
        let clone = boxes.firstElementChild.cloneNode(true);
        clone.querySelector("input[type='text']").value = "";
        clone.querySelector("input[type='hidden']").value = "no";
        clone.querySelector("input[type='checkbox']").value = "yes";
        clone.querySelector("input[type='checkbox']").checked = false;
        clone.querySelector("input[type='checkbox']").id = "subCatNamePage" + Math.floor(Math.random() * 100) + 1;
        boxes.appendChild(clone);
    };
}

function removeSubCategory(element) {
    let parent = element.parentNode.parentNode.parentNode.parentNode;
    let boxes = document.getElementById("formRowSubCat");
    if (boxes.childElementCount > 1) {
        parent.remove();
    } else {
        var popup = document.getElementById("myPopup");
        var node = document.createElement("span");
        var textnode = document.createTextNode("Не може да изтриете последното поле!");
        node.appendChild(textnode);
        popup.querySelector("p[id='message']").appendChild(node);
        popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
        popup.classList.toggle("show");
        popup.style.display = "block";
    }
};
//-------------------------------------------------------------//

// Logic for reordering the items of the menu
function initiateDrag(type, id) {    
    deactivateButtons();
    if (type == 'categories') {
        this.onclick = function() {
            var elementContainer = document.getElementsByClassName('orderCategories');
            for (var index = 0; index < elementContainer.length; index++) {
                elementContainer[index].classList.add("cont");
            }

            var elementCategory = document.getElementsByClassName('category');
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("draggable");
                elementCategory[index].draggable = true;
            }

            var elementCategory = document.getElementsByClassName('categoryOrder');
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("position");
            }
            var form = document.getElementById('orderForm');
            form.action = "/categoryorder";
            getOrder(type, id);
            document.getElementById('saveOrder').classList.remove('display-none');
            document.getElementById('cancelReorder').classList.remove('display-none');
            const draggables = document.querySelectorAll('.draggable');
            const containers = document.querySelectorAll('.cont');
            drag(draggables, containers, type, null);
        };
    } else if (type == 'products') { //TODO: Check where it is used exept partners 
        
        this.onclick = function() {
            var elementContainer = document.getElementsByClassName('orderProducts');
            for (var index = 0; index < elementContainer.length; index++) {
                elementContainer[index].classList.add("cont");
            }

            var elementCategory = document.getElementsByClassName('product');
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("draggable");
                elementCategory[index].draggable = true;
            }

            var elementCategory = document.getElementsByClassName('productOrder');
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("position");
            }
            var form = document.getElementById('orderForm-' + id);
            form.action = "/productorder";
            getOrder(type, id);

            const draggables = document.querySelectorAll('.draggable');
            const containers = document.querySelectorAll('.cont');            
            drag(draggables, containers, type, id);
        };
    } else if (type == 'parameters') {
        this.onclick = function() {
            var elementContainer = document.getElementsByClassName('orderParameters');
            for (var index = 0; index < elementContainer.length; index++) {
                elementContainer[index].classList.add("cont");
            }

            var elementCategory = document.getElementsByClassName('parameter');
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("draggable");
                elementCategory[index].draggable = true;
            }

            document.getElementById('start').classList.add('display-none');
            document.getElementById('cancel').classList.remove('d-none');
            document.getElementById('dellParam').classList.add('display-none');
            document.getElementById('addParam').classList.add('display-none');

            const draggables = document.querySelectorAll('.draggable');
            const containers = document.querySelectorAll('.cont');
            drag(draggables, containers, type, id);
        };
    } else {
        this.onclick = function() {
            var elementContainer = document.getElementsByClassName('orderSubcategories-' + type);
            for (var index = 0; index < elementContainer.length; index++) {
                elementContainer[index].classList.add("cont");
            }

            var elementCategory = document.getElementsByClassName('subcategory-' + type);
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("draggable");
                elementCategory[index].draggable = true;
            }

            var elementCategory = document.getElementsByClassName('subcategoryOrder-' + type);
            for (var index = 0; index < elementCategory.length; index++) {
                elementCategory[index].classList.add("position");
            }
            var form = document.getElementById('orderForm');
            form.action = "/subcategoryorder";
            getOrder(type, id);
            const draggables = document.querySelectorAll('.draggable');
            const containers = document.querySelectorAll('.cont');
            drag(draggables, containers, type, null);
        };

        document.getElementById('saveOrder').classList.remove('display-none');
        document.getElementById('cancelReorder').classList.remove('d-none');
        const draggables = document.querySelectorAll('.draggable');
        const containers = document.querySelectorAll('.cont');
        drag(draggables, containers, type, null);
    }

}

function cancelReorder() {
    activateButtons();
    this.onclick = function() {
        var elementContainer = document.getElementsByClassName('orderParameters');
        for (var index = 0; index < elementContainer.length; index++) {
            elementContainer[index].classList.remove("cont");
        }

        var elementCategory = document.getElementsByClassName('parameter');
        for (var index = 0; index < elementCategory.length; index++) {
            elementCategory[index].classList.remove("draggable");
            elementCategory[index].draggable = true;
            elementCategory[index].setAttribute("draggable", "false");
        }

        document.getElementById('start').classList.remove('display-none');
        document.getElementById('cancel').classList.add('d-none');
        document.getElementById('dellParam').classList.remove('display-none');
        document.getElementById('addParam').classList.remove('display-none');

        const draggables = document.querySelectorAll('.draggable');
        const containers = document.querySelectorAll('.cont');

    };



}

function drag(draggables, containers, type, id) {
    draggables.forEach(draggable => {
        draggable.addEventListener('dragstart', () => {
            draggable.classList.add('dragging');
        })
        draggable.addEventListener('dragend', () => {
            draggable.classList.remove('dragging');
            getOrder(type, id);
        })
    })

    containers.forEach(container => {
        container.addEventListener('dragover', e => {
            e.preventDefault()
            const afterElement = getDragAfterElement(container, e.clientY)
            const draggable = document.querySelector('.dragging')
            if (afterElement == null) {
                container.appendChild(draggable)
            } else {
                container.insertBefore(draggable, afterElement)
            }
        })
    })

    function getDragAfterElement(container, y) {
        const draggableEl = [...container.querySelectorAll('.draggable:not(.dragging)')]

        return draggableEl.reduce((closest, child) => {
            const box = child.getBoundingClientRect();
            const offset = y - box.top - box.height / 2;

            if (offset < 0 && offset > closest.offset) {
                return { offset: offset, element: child }
            } else {
                return closest;
            }
        }, { offset: Number.NEGATIVE_INFINITY }).element
    }
}

function getOrder(type, id) {
    if (type == 'products') {
        let tab = document.getElementById("catList-" + id);
        var elementCategory = tab.querySelectorAll("input[class='position']");
        let form = tab.querySelector("form[id=orderForm-" + id + "]");
        let inputs = form.querySelectorAll("input[name='ordercategory[]']");
        for (let index = inputs.length - 1; index >= 0; index--) {
            inputs[index].parentNode.removeChild(inputs[index]);
        }

        for (let index = 0; index < elementCategory.length; index++) {
            let el = document.createElement("input");
            // el.hidden = true;
            el.name = "ordercategory[]";
            el.setAttribute("type", "text");
            el.setAttribute("value", elementCategory[index].value);
            form.appendChild(el)
        }

        tab.querySelector("a[id='initiate']").classList.add('display-none');
        tab.querySelector("a[id='create']").classList.add('display-none');
        tab.querySelector("a[id='saveOrder']").classList.remove('display-none');
        tab.querySelector("a[id='cancelReorder']").classList.remove('display-none');
    } else {
        var elementCategory = document.getElementsByClassName('position');
        let form = document.getElementById("orderForm");
        let inputs = document.getElementsByName("ordercategory[]");
        for (let index = inputs.length - 1; index >= 0; index--) {
            inputs[index].parentNode.removeChild(inputs[index]);
        }

        for (let index = 0; index < elementCategory.length; index++) {
            let el = document.createElement("input");
            el.hidden = true;
            el.name = "ordercategory[]";
            el.setAttribute("type", "text");
            el.setAttribute("value", elementCategory[index].value);
            form.appendChild(el)
        }
    }
}

function saveO(id) {
    let form = document.querySelector("form[id=orderForm-" + id + "]");
    form.submit();
}
// ---------------------------------------------------------------//

// Templates
// VIEW TEMPLATES
if (document.getElementById("createPageBtn") !== null) {
    document.getElementById("createPageBtn").onclick = function() {
        var categoryId = this.getAttribute('data-id');
        var templateId = this.getAttribute('data-tempid');
        var templateLevel = this.getAttribute('data-level');
        var form = document.getElementById("templates");
        document.getElementById("addParam").classList.remove('display-none');
        document.getElementById("editParam").classList.remove('display-none');



        document.getElementById("uncreated").classList.add('display-none');
        document.getElementById("existing").classList.remove('display-none');

        if (document.getElementById("paramfields") != null) {
            document.getElementById("paramfields").classList.remove('display-none');
        }


        if (templateId === 'null' && templateLevel === 'category') {
            form.action = "/createCategoryTemplate/" + categoryId;
        } else if (templateId !== 'null' && templateLevel === 'category') {
            form.action = "/editCategoryTemplate/" + categoryId + "/" + templateId;
        } else if (templateId === 'null' && templateLevel === 'subcategory') {
            form.action = "/createSubcategoryTemplate/" + categoryId;
        } else if (templateId !== 'null' && templateLevel === 'subcategory') {
            form.action = "/editSubcategoryTemplate/" + categoryId + "/" + templateId;
            document.getElementById('paramCopyBtn').classList.add('d-none');
        }

        var inputs = form.querySelectorAll('input');
        for (let index = 0; index < inputs.length; index++) {
            var element = inputs[index];
            element.disabled = false;
            element.classList.remove('disabled-field');
        }

        var selects = form.querySelectorAll('select');
        for (let index = 0; index < selects.length; index++) {
            var element = selects[index];
            element.disabled = false;
            element.classList.remove('disabled-field');
            var options = element.querySelectorAll('option');
            for (let index = 0; index < options.length; index++) {
                var element = options[index].classList.remove('text-white');;
            }
        }

        document.getElementById("element-selector").classList.remove('display-none')
        document.getElementById("element-selectorTwo").classList.remove('display-none')
        document.getElementById("selected-elements").classList.add('display-none')

        deactivateButtons();
        form.getElementsByClassName("left-menu-btn")[0].classList.remove('disabled')
        form.getElementsByClassName("left-menu-btn")[0].toggleAttribute('disabled');
        var invisibleBTN = form.getElementsByClassName('btn-transparent');
        for (var i = 0; i < invisibleBTN.length; i++) {
            invisibleBTN[i].classList.remove('display-none');
        }
        document.getElementById("createPageBtn").classList.add('d-none');

        if (document.getElementById('existingparamfields').childElementCount > 0 && document.getElementById("paramfields") != null) {
            document.getElementById('paramfields').remove();
        }
    }
}

if (document.querySelector('select[id=page-type]') !== null) {

    document.querySelector('select[id=page-type]').onload = function() {
        if (this.value === 'product') {
            var parametersForm = document.getElementById("parameters-form");
            parametersForm.classList.remove('display-none');
            var inputs = parametersForm.querySelectorAll('input');

            for (let index = 0; index < inputs.length; index++) {
                inputs[index].disabled = false;
            }
        } else {
            var parametersForm = document.getElementById("parameters-form");
            parametersForm.classList.add('display-none');
            var inputs = parametersForm.querySelectorAll('input');

            for (let index = 0; index < inputs.length; index++) {
                inputs[index].disabled = true;
            }
        }
    }
    document.querySelector('select[id=page-type]').onchange = function() {
        if (this.value === 'product') {
            var parametersForm = document.getElementById("parameters-form");
            parametersForm.classList.remove('display-none');
            var inputs = parametersForm.querySelectorAll('input');

            for (let index = 0; index < inputs.length; index++) {
                inputs[index].disabled = false;
            }
        } else {
            var parametersForm = document.getElementById("parameters-form");
            parametersForm.classList.add('display-none');
            var inputs = parametersForm.querySelectorAll('input');

            for (let index = 0; index < inputs.length; index++) {
                inputs[index].disabled = true;
            }
        }
    }
}

if (document.querySelector('select[id=elementsToSelect]') !== null) {
    document.querySelector('select[id=elementsToSelect]').onclick = function() {
        var selected = document.querySelector('div[id=selectedElements]');
        var node = document.createElement("div");
        var remove = document.createElement("div");
        var text = document.createElement("input");
        text.classList.add('border-0');
        text.setAttribute('name', 'chosneElementName[]');
        var input = document.createElement("input");
        input.setAttribute('value', this.value);
        input.setAttribute('name', 'selectedElements[]');
        input.setAttribute('hidden', true);
        var selectedElementId = document.createElement("input");
        selectedElementId.setAttribute('value', 'new');
        selectedElementId.setAttribute('hidden', true);
        selectedElementId.setAttribute('name', 'selectedElementsId[]');
        text.style = " user-select: none;";
        text.value = this.options[this.selectedIndex].text;
        remove.setAttribute("onClick", "javascript: deleteElem(this);")
        remove.innerHTML = "X";
        remove.classList.add('btn', 'btn-outline-danger', 'py-0', 'px-1');
        node.append(text)
        node.append(remove)
        node.append(input)
        node.append(selectedElementId)
        node.setAttribute("onMouseDown", "javascript: dragElem();")
        node.setAttribute("draggable", true)
        node.classList.add('form-control', 'mb-2', 'd-flex', 'justify-content-between', 'draggable')
        selected.appendChild(node)
    }
}

function deleteElem(element) {
    element.parentNode.remove();
}

function dragElem() {
    const draggables = document.querySelectorAll('.draggable');
    const containers = document.querySelectorAll('.elementContainer');
    const elementNames = document.querySelectorAll('input[name="chosneElementName[]"]');
    draggables.forEach(draggable => {
        draggable.addEventListener('dragstart', () => {
            draggable.classList.add('dragging');

            elementNames.forEach(element => {
                element.disabled = true;
            });
        })

        draggable.addEventListener('dragend', () => {
            draggable.classList.remove('dragging');
            elementNames.forEach(element => {
                element.disabled = false;
            });
        })
    })

    containers.forEach(container => {
        container.addEventListener('dragover', e => {
            e.preventDefault()
            const afterElement = getDragAfterElement(container, e.clientY)
            const draggable = document.querySelector('.dragging')
            if (afterElement == null) {
                container.appendChild(draggable)
            } else {
                container.insertBefore(draggable, afterElement)
            }
        })
    })

    function getDragAfterElement(container, y) {
        const draggableEl = [...container.querySelectorAll('.draggable:not(.dragging)')]

        return draggableEl.reduce((closest, child) => {
            const box = child.getBoundingClientRect();
            const offset = y - box.top - box.height / 2;

            if (offset < 0 && offset > closest.offset) {
                return { offset: offset, element: child }
            } else {
                return closest;
            }
        }, { offset: Number.NEGATIVE_INFINITY }).element
    }
}

function chooseTypeP(element) {
    let parent = element.parentNode.parentNode;
    var numQuantifiers = element.parentNode.parentNode.getElementsByClassName('num-quantifier');
    var quantifiers = element.parentNode.parentNode.getElementsByClassName('quantifier');
    var stringMetric = element.parentNode.parentNode.getElementsByClassName('str-metric');
    var linked = element.parentNode.parentNode.getElementsByClassName('linked');
    if (parent.querySelector('input[name="decimalprecision[]"]') !== null) {
        parent.querySelector('input[name="decimalprecision[]"]').classList.add('display-none');
        parent.querySelector('input[name="decimalprecision[]"]').value = "";

        for (var index = 0; index < numQuantifiers.length; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }
            if (numQuantifiers[index].querySelector('input').type === 'date' || numQuantifiers[index].querySelector('input').type === 'time') {
                numQuantifiers[index].querySelector('input').type = 'number'
            }
        }
    }

    if (element.value === "str" || element.value === "str-url") {
        parent.querySelector('label[for=precision]').innerHTML = "Брой символи";
        parent.querySelector('input[name="precision[]"]').placeholder = "Брой символи";
        parent.querySelector('input[name="precision[]"]');
        for (var index = 0; index < numQuantifiers.length; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }
            if (numQuantifiers[index].querySelector('input').type === 'date' || numQuantifiers[index].querySelector('input').type === 'time') {
                numQuantifiers[index].querySelector('input').type = 'number'
            }
        }
        for (var index = 0; index < stringMetric.length; index++) {
            if (stringMetric[index].classList.contains('d-none')) {
                stringMetric[index].classList.remove('d-none');
            }
        }
        parent.querySelector('label[for=precision]').parentNode.classList.remove('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
    } else if (element.value === "int") {
        parent.querySelector('label[for=precision]').innerHTML = "Разряди";
        parent.querySelector('input[name="precision[]"]').placeholder = "Разряди";
        parent.querySelector('input[name="precision[]"]');
        for (var index = 0; index < numQuantifiers.length; index++) {
            if (numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.remove('d-none');
            }
            if (numQuantifiers[index].querySelector('input').type === 'date' || numQuantifiers[index].querySelector('input').type === 'time') {
                numQuantifiers[index].querySelector('input').type = 'number'
            }
        }
        parent.querySelector('label[for=precision]').parentNode.classList.remove('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
    } else if (element.value === "float") {
        parent.querySelector('label[for=precision]').innerHTML = "Разряди/Прецизност";
        parent.querySelector('input[name="precision[]"]');
        parent.querySelector('input[name="precision[]"]').name = 'precision[]';
        parent.querySelector('div[name=precision-container]').classList.add('d-flex-inline');
        parent.querySelector('input[name="decimalprecision[]"]').classList.remove('display-none');
        for (var index = 0; index < numQuantifiers.length; index++) {
            if (numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.remove('d-none');
            }
            if (numQuantifiers[index].querySelector('input').type === 'date' || numQuantifiers[index].querySelector('input').type === 'time') {
                numQuantifiers[index].querySelector('input').type = 'number'
            }
        }

        parent.querySelector('label[for=precision]').parentNode.classList.remove('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
    } else if (element.value === "bool") {
        for (var index = 0; index < quantifiers.length; index++) {
            if (!quantifiers[index].classList.contains('d-none')) {
                quantifiers[index].classList.add('d-none');
            }
        }
    } else if (element.value === "date") {
        for (var index = 0; index < numQuantifiers.length - 2; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }
        }

        for (var index = numQuantifiers.length - 1; index > numQuantifiers.length - 3; index--) {
            if (numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.remove('d-none');
                numQuantifiers[index].querySelector('input').type = 'date';
            }
        }

        parent.querySelector('label[for=precision]').parentNode.classList.add('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
        for (var index = 0; index < stringMetric; index++) {
            if (!stringMetric[index].classList.contains('d-none')) {
                stringMetric[index].classList.add('d-none');
            }
        }
    } else if (element.value === "hour") {
        for (var index = 0; index < numQuantifiers.length - 2; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }
        }

        for (var index = numQuantifiers.length - 1; index > numQuantifiers.length - 3; index--) {
            if (numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.remove('d-none');
                numQuantifiers[index].querySelector('input').type = 'time';
            }
        }

        parent.querySelector('label[for=precision]').parentNode.classList.add('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
        for (var index = 0; index < stringMetric; index++) {
            if (!stringMetric[index].classList.contains('d-none')) {
                stringMetric[index].classList.add('d-none');
            }
        }
    } else if (element.value === "complex") {
        for (var index = 0; index < numQuantifiers.length - 2; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }
        }
        parent.querySelector('label[for=precision]').parentNode.classList.add('d-none');
        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
    } else if (element.value === "linked") {
        for (var index = 0; index < linked.length; index++) {
            if (linked[index].classList.contains('d-none')) {
                linked[index].classList.remove('d-none');
            }
        }
        for (var index = 0; index < quantifiers.length; index++) {
            if (!quantifiers[index].classList.contains('d-none')) {
                quantifiers[index].classList.add('d-none');
            }
        }
    } else {
        if (parent.querySelector('input[name="decimalprecision[]"]') !== null) {
            parent.querySelector('input[name="decimalprecision[]"]').classList.add('display-none');
            parent.querySelector('input[name="decimalprecision[]"]').value = "";
            parent.querySelector('input[name="decimalprecision[]"]').classList.add('display-none');
        }
        parent.querySelector('label[for=precision]').innerHTML = "Ограничител";
        parent.querySelector('input[name="precision[]"]').placeholder = "Ограничител";
        parent.querySelector('input[name="precision[]"]');
        for (var index = 0; index < quantifiers.length; index++) {
            if (!quantifiers[index].classList.contains('d-none')) {
                quantifiers[index].classList.add('d-none');
            }
        }
        for (var index = 0; index < numQuantifiers.length; index++) {
            if (!numQuantifiers[index].classList.contains('d-none')) {
                numQuantifiers[index].classList.add('d-none');
            }

            if (numQuantifiers[index].querySelector('input').type === 'date' || numQuantifiers[index].querySelector('input').type === 'time') {
                numQuantifiers[index].querySelector('input').type = 'number'
            }
        }

        for (var index = 0; index < linked.length; index++) {
            if (!linked[index].classList.contains('d-none')) {
                linked[index].classList.add('d-none');
            }
        }
        // parent.querySelector('label[for=precision]').parentNode.classList.remove('d-none');
    }
}

if (document.getElementById("addParam") !== null) {
    document.getElementById("addParam").onclick = function() {
        let boxes = document.getElementById("parameters-form");

        if (boxes.querySelector('div[id=existingparamfields]').lastElementChild === null) {
            var clone = boxes.querySelector('div[id=paramfields]').cloneNode(true);
        } else {
            var clone = boxes.querySelector('div[id=existingparamfields]').lastElementChild.cloneNode(true);
        }

        clone.id = clone.id + Math.floor(Math.random() * 100) + 1;
        let inputs = clone.querySelectorAll('input');
        for (let index = 0; index < inputs.length; index++) {
            if (inputs[index].name === 'pId[]') {
                inputs[index].setAttribute('value', -1);
            }
            if (inputs[index].type === 'checkbox') {
                inputs[index].checked = false;
                if (inputs[index].id.startsWith('primeParam')) {
                    inputs[index].id = 'primeParam' + Math.floor(Math.random() * 100) + 1;
                    inputs[index].value = "yes";
                } else {
                    inputs[index].value = "no";
                }
                if (inputs[index].id.startsWith('groupParam')) {
                    inputs[index].id = 'groupParam' + Math.floor(Math.random() * 100) + 1;
                    inputs[index].value = "yes";
                } else {
                    inputs[index].value = "no";
                }
            } else if (inputs[index].type === 'hidden') {
                inputs[index].value = "no";
            } else {
                inputs[index].value = "";
            }
        }

        let select = clone.querySelectorAll('select');

        for (var ind = 0; ind < select.length; ind++) {
            for (let index = 0; index < select[ind].length; index++) {
                if (select[ind][index].selected === true) {
                    select[ind][index].removeAttribute('selected');
                }
            }
        }
        let invFields = clone.getElementsByClassName('quantifier');

        for (var index = 0; index < invFields.length; index++) {
            if (!invFields[index].classList.contains('d-none')) {
                invFields[index].classList.add('d-none');
            }
        }

        invFields = clone.getElementsByClassName('linked');

        for (var index = 0; index < invFields.length; index++) {
            if (!invFields[index].classList.contains('d-none')) {
                invFields[index].classList.add('d-none');
            }
        }

        invFields = clone.getElementsByClassName('num-quantif');

        for (var index = 0; index < invFields.length; index++) {
            if (!invFields[index].classList.contains('d-none')) {
                invFields[index].classList.add('d-none');
            }
        }
        boxes.appendChild(clone)
    };
}

if (document.getElementById("cancelTemp") !== null) {
    document.getElementById("cancelTemp").onclick = function() {
        var form = document.getElementById("templates");
        var inputs = form.querySelectorAll('input');
        for (let index = 0; index < inputs.length; index++) {
            var element = inputs[index];
            element.disabled = true;
            element.classList.add('disabled-field');
        }

        var selects = form.querySelectorAll('select');
        for (let index = 0; index < selects.length; index++) {
            var element = selects[index];
            element.disabled = true;
            element.classList.add('disabled-field');
            var options = element.querySelectorAll('option');
            for (let index = 0; index < options.length; index++) {
                var element = options[index].classList.add('text-white');
            }
        }
        document.getElementById("createPageBtn").classList.add('d-none');
        document.getElementById("element-selector").classList.add('display-none');
        document.getElementById("element-selectorTwo").classList.add('display-none');
        document.getElementById("selected-elements").classList.remove('display-none');
        if (document.getElementById("paramfields") !== null) {
            document.getElementById("paramfields").classList.add('display-none');
        }
        document.getElementById("addParam").classList.add('display-none');
        activateButtons();
        var invisibleBTN = form.getElementsByClassName('btn-transparent');
        for (var i = 0; i < invisibleBTN.length; i++) {
            if (!invisibleBTN[i].classList.contains('left-menu-btn') && invisibleBTN[i].id !== 'createPageBtn') {
                invisibleBTN[i].classList.add('display-none');
            }
        }
        document.getElementById("createPageBtn").classList.remove('d-none');
    };
}
// ---------------------------------------------------------------//

//CREATES THE TEMPLATE TABLE
function createTemplate(templateForm, level, type, categoryId) {
    templateForm.classList.remove('display-none');
    templateForm.querySelector("select[id='page-type']").querySelector("option[value='" + type + "']").selected = true;
    var selected = document.getElementById('selectedElements');
    destroySelected(selected);
    pages.forEach(page => {

        pageElements.forEach(pageElement => {
            if (page['level'] === level && page['categoryId'] === parseInt(categoryId) && page['id'] === pageElement['pageId']) {

                var li = document.createElement('li');
                interfaceElements.forEach(element => {
                    if (element['id'] === pageElement['elementId']) {
                        li.innerHTML = pageElement['elementName'];
                        var node = document.createElement("div");
                        var remove = document.createElement("div");
                        var text = document.createElement("input");
                        text.classList.add('border-0');
                        text.setAttribute('name', 'chosneElementName[]');
                        var selectedElementId = document.createElement("input");
                        selectedElementId.setAttribute('value', pageElement['id']);
                        selectedElementId.setAttribute('hidden', true);
                        selectedElementId.setAttribute('name', 'selectedElementsId[]');
                        var input = document.createElement("input");
                        input.setAttribute('value', element['id']);
                        input.setAttribute('name', 'selectedElements[]');
                        input.setAttribute('hidden', true);
                        text.style = " user-select: none;";
                        text.value = pageElement['elementName'];
                        remove.setAttribute("onClick", "javascript: deleteElem(this);")
                        remove.innerHTML = "X";
                        remove.classList.add('btn', 'btn-outline-danger', 'py-0', 'px-1');
                        node.append(text)
                        node.append(remove)
                        node.append(input)
                        node.append(selectedElementId)
                        node.setAttribute("onMouseDown", "javascript: dragElem();")
                        node.setAttribute("draggable", true)
                        node.classList.add('form-control', 'mb-2', 'd-flex', 'justify-content-between', 'draggable', 'sElement')
                        selected.appendChild(node)
                    }
                });

                templateForm.querySelector("div[id='elements']").querySelector("ul").appendChild(li);
            }
        });
    });
}

function destroyTemplate(templateForm) {
    while (templateForm.querySelector("div[id='elements']").querySelector("ul").lastElementChild) {
        templateForm.querySelector("div[id='elements']").querySelector("ul").removeChild(templateForm.querySelector("div[id='elements']").querySelector("ul").lastElementChild);
    }
}

function destroySelected(selectedForm) {
    selectedForm.innerHTML = '';
}
//CREATES THE PARAMETER TABLE
function createParameters(productParameters, tempId, tempType, paramForm) {
    //console.log(productParameters, tempId, tempType, paramForm);
    if (productParameters !== null) {
        productParameters.forEach(param => {
            var pId = param['id'];
            var paramId = param['pageId'];
            if (paramId === tempId) {
                var paramPage = param['pageId'];
                var paramName = param['name'];
                var paramNameEn = param['nameEn'];
                var paramType = param['dataType'];
                var paramMetric = param['metricName'];
                var paramMetricEn = param['metricNameEn'];
                var paramPrecision = param['metricBoundaries'];
                var paramPrime = param['primaryParam'];
                var paramGroup = param['groupParam'];
                var paramMax = param['max'];
                var paramMin = param['min'];
                var paramSource = param['source'];

                if (tempType === 'product') {
                    var params = document.getElementById('paramfields');
                    if (params == null) {
                        params = document.getElementById('existingparamfields');
                    }

                    var paramFields = params.cloneNode(true);
                    paramFields.id = 'paramfields-' + Math.floor(Math.random() * 100) + 1;
                    paramFields.classList.add('pb-2');
                    paramFields.childNodes.forEach(field => {
                        if (field.lastChild !== null) {
                            if (field.querySelector('input') !== null) {
                                switch (field.querySelector('input').name) {
                                    case ('pId[]'):
                                        field.querySelector('input').setAttribute('value', pId);
                                        break;
                                    case ('nameBg[]'):
                                        field.querySelector('input').value = paramName;
                                        break;
                                    case ('nameEn[]'):
                                        field.querySelector('input').setAttribute('value', paramNameEn);
                                        break;
                                    case ('precision[]'):
                                        if (paramType === "float") {
                                            if (paramPrecision !== null) {
                                                var temp = paramPrecision.toString().split(".");
                                                var precision = temp[0];
                                                var decimalprecision = temp[1];
                                            } else {
                                                var precision = paramPrecision;
                                                var decimalprecision = paramPrecision;
                                            }

                                            field.querySelector('input').setAttribute('value', precision);
                                            field.querySelector("input[name='decimalprecision[]']").classList.remove('display-none');
                                            field.querySelector("input[name='decimalprecision[]']").setAttribute('value', decimalprecision);
                                        } else {
                                            if (paramPrecision !== null) {
                                                field.querySelector('input').setAttribute('value', paramPrecision);
                                            }
                                        }
                                        break;
                                    case ('metricBg[]'):
                                        if (paramMetric !== null) {
                                            field.querySelector('input').setAttribute('value', paramMetric);
                                        }
                                        break;
                                    case ('metricEn[]'):
                                        if (paramMetricEn !== null) {
                                            field.querySelector('input').setAttribute('value', paramMetricEn);
                                        }
                                        break;
                                    case ('max[]'):
                                        if (paramMax !== null) {
                                            field.querySelector('input').setAttribute('value', paramMax);
                                        }
                                        break;
                                    case ('min[]'):
                                        if (paramMin !== null) {
                                            field.querySelector('input').setAttribute('value', paramMin);
                                        }
                                        break;
                                    case ('primary[]'):
                                        field.querySelector('input').setAttribute('value', paramPrime);
                                        field.querySelector('label').classList.add('text-white');
                                        if (paramPrime === 'yes') {
                                            field.querySelector('input[name=pagevis]').setAttribute('value', 'no');
                                            field.querySelector('input[name=pagevis]').setAttribute('checked', true);
                                            field.querySelector('input[name=pagevis]').id = 'primeParam' + Math.floor(Math.random() * 100) + 1;
                                        } else {
                                            field.querySelector('input[name=pagevis]').setAttribute('value', 'yes');
                                            field.querySelector('input[name=pagevis]');
                                            field.querySelector('input[name=pagevis]').id = 'primeParam' + Math.floor(Math.random() * 100) + 1;
                                        }

                                        break;
                                    case ('group[]'):
                                        field.querySelector('input').setAttribute('value', paramGroup);
                                        field.querySelector('label').classList.add('text-white');

                                        if (paramGroup === 'yes') {
                                            field.querySelector('input[name=groupP]').setAttribute('value', 'no');
                                            field.querySelector('input[name=groupP]').setAttribute('checked', true);
                                            field.querySelector('input[name=groupP]').id = 'groupParam' + Math.floor(Math.random() * 100) + 1;

                                        } else {
                                            field.querySelector('input[name=groupP]').setAttribute('value', 'yes');
                                            field.querySelector('input[name=groupP]');
                                            field.querySelector('input[name=groupP]').id = 'groupParam' + Math.floor(Math.random() * 100) + 1;
                                        }
                                        break;
                                }
                            } else if (field.querySelectorAll('select') !== null) {
                                var selects = field.querySelectorAll('select');
                                for (var selectIndex = 0; selectIndex < selects.length; selectIndex++) {
                                    if (selects[selectIndex].classList.contains('src')) {
                                        for (let optionIndex = 0; optionIndex < selects[selectIndex].options.length; optionIndex++) {
                                            if (selects[selectIndex].options[optionIndex].value == paramSource) {
                                                selects[selectIndex].options[optionIndex].setAttribute('selected', true);
                                            }
                                        }
                                    } else {
                                        selects[selectIndex].querySelector('option[value=' + paramType + ']').setAttribute('selected', true);
                                    }
                                }
                            }

                            if (paramType === 'int' || paramType === 'float') {
                                invFields = paramFields.getElementsByClassName('num-quantifier');
                                for (var index = 0; index < invFields.length; index++) {
                                    invFields[index].classList.remove('d-none');
                                }
                                invFields = paramFields.getElementsByClassName('quantifier');

                                for (var index = 0; index < invFields.length; index++) {
                                    if (invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.remove('d-none');
                                    }
                                }
                            } else if (paramType === 'date') {
                                invFields = paramFields.getElementsByClassName('num-quantifier');

                                for (var index = 0; index < invFields.length - 2; index++) {
                                    if (!invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.add('d-none');
                                    }
                                }

                                for (var index = invFields.length - 1; index > invFields.length - 3; index--) {
                                    if (invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.remove('d-none');
                                        invFields[index].querySelector('input').type = 'date';
                                    }
                                }

                                // paramFields.querySelector('label[for=precision]').parentNode.classList.add('d-none');
                            } else if (paramType === 'hour') {
                                invFields = paramFields.getElementsByClassName('num-quantifier');

                                for (var index = 0; index < invFields.length - 2; index++) {
                                    if (!invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.add('d-none');
                                    }
                                }

                                for (var index = invFields.length - 1; index > invFields.length - 3; index--) {
                                    if (invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.remove('d-none');
                                        invFields[index].querySelector('input').type = 'time';
                                    }
                                }
                                paramFields.querySelector('label[for=precision]').parentNode.classList.add('d-none');
                            } else if (paramType === 'linked') {
                                invFields = paramFields.getElementsByClassName('linked');
                                for (var index = 0; index < invFields.length; index++) {
                                    if (invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.remove('d-none');
                                    }
                                }
                                paramFields.querySelector('label[for=precision]').parentNode.classList.add('d-none');
                            } else if (paramType === 'str' || paramType === 'str-url' || paramType === 'key-value-pair') {
                                invFields = paramFields.getElementsByClassName('quantifier');

                                for (var index = 0; index < invFields.length; index++) {
                                    if (invFields[index].classList.contains('d-none')) {
                                        invFields[index].classList.remove('d-none');
                                    }
                                }
                                // paramFields.querySelector('label[for=precision]').parentNode.classList.add('d-none');
                            }
                        }
                    });

                    paramForm.append(paramFields);

                    paramForm.childNodes.forEach(element => {
                        if (element.nodeName === 'DIV') {
                            element.classList.remove('display-none');
                        }
                    });

                    document.getElementById("noparamfields").classList.add('display-none');
                    if (document.getElementById("paramfields") !== null) {
                        document.getElementById("paramfields").classList.add('display-none');
                    }

                    document.getElementById("parameters-form").classList.remove('display-none');
                    paramForm.classList.remove("display-none");
                }
            }
        });
    }
}

function destroyParamForm(paramForm) {
    while (paramForm.lastElementChild) {
        paramForm.removeChild(paramForm.lastElementChild);
    }
}
//-----------------------------------------------------------//




//SYSTEM ACTIVITY
function activateButtons() {
    var leftButtons = document.getElementsByClassName("left-menu-btn");
    for (let index = 0; index < leftButtons.length; index++) {
        leftButtons[index].classList.remove('disabled');
    }
};

function deactivateButtons() {
    var leftButtons = document.getElementsByClassName("left-menu-btn");
    for (let index = 0; index < leftButtons.length; index++) {
        leftButtons[index].classList.add('disabled');
    }
};

if (document.getElementById("closePopup") !== null) {
    document.getElementById("closePopup").onclick = function() {
        var popup = document.getElementById("myPopup");
        popup.querySelector("span").remove();
        popup.classList.toggle("show");
        popup.style.display = "none";
    };
}
if (document.getElementById('editContent') !== null) {
    document.getElementById('editContent').onclick = function() {
        var content = document.getElementById("editCatContent");
        var disabled = content.querySelectorAll('input[disabled]');
        for (let index = 0; index < disabled.length; index++) {
            var element = disabled[index];
            element.classList.remove('disabled-field');
            element.disabled = false;
        }
        var btm = content.querySelectorAll('button[disabled]');
        for (let index = 0; index < btm.length; index++) {
            var element = btm[index];
            element.classList.remove('disabled-field');
            element.disabled = false;
        }

        var btnhid = content.querySelectorAll('.display-none');
        for (let index = btnhid.length - 1; index >= 0; index--) {
            var element = btnhid[index];
            element.classList.remove('display-none');
        }

        //tineMCY initiate

        var text = [];
        var editors = document.getElementsByClassName('text-view');
        var tinyeditors = document.getElementsByClassName('tiny');
        for (let index = 0; index < editors.length; index++) {
            var editor = editors[index];
            text.push(editor.textContent);
            tinyeditors[index].classList.remove('d-none');
            editor.classList.add('d-none');
        }

        tinymce.init({
            selector: '#text-bg',
            width: 'auto',
            height: 200,
            menubar: '',
            paste_as_text: true,
            plugins: [
                'advlist autolink link image lists charmap print preview hr anchor pagebreak paste textpattern '
            ],
            my_custom_variable: 'test',
            toolbar: 'undo redo | styleselect | bold italic  alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link image |',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            strict_loading_mode: true,
            setup: function(editor) {
                editor.on('init', function(e) {
                    editor.setContent(text[0]);
                });
            }
        });

        tinymce.init({
            selector: '#text-en',
            width: 'auto',
            height: 200,
            menubar: '',
            paste_as_text: true,
            plugins: [
                'advlist autolink link image lists charmap print preview hr anchor pagebreak paste textpattern'
            ],
            my_custom_variable: 'test',
            toolbar: 'undo redo | styleselect | bold italic | styleselect | alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link image |',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            strict_loading_mode: true,
            setup: function(editor) {
                editor.on('init', function(e) {
                    editor.setContent(text[1]);
                });
            }
        });
        deactivateButtons();
    }
}

if (document.getElementById('cancelCreateContent') !== null) {
    document.getElementById('cancelCreateContent').onclick = function() {
        var content = document.getElementById("editCatContent");
        var disabled = content.querySelectorAll('input');
        for (let index = 0; index < disabled.length; index++) {
            var element = disabled[index];
            element.classList.add('disabled-field');
            element.disabled = true;
        }
        var btm = content.querySelectorAll('button');
        for (let index = 0; index < btm.length; index++) {
            var element = btm[index];
            element.classList.remove('disabled-field');
            element.disabled = false;
        }

        var btnhid = content.querySelectorAll('button');
        for (let index = btnhid.length - 1; index > 0; index--) {
            var element = btnhid[index];
            element.classList.add('display-none');
        }
        document.getElementById('cancelCreateContent').classList.add('display-none');
        activateButtons();
    }
}

if (document.getElementById('createContactBtn') !== null) {
    document.getElementById('createContactBtn').onclick = function() {
        var contactTable = document.getElementById('createContactTable');
        contactTable.classList.remove('d-none');
    }
}


function deleteContact(valueId) {
    var popup = document.getElementById("myPopup");
    var node = document.createElement("span");
    var textnode = document.createTextNode("Това ще изтрие избрани контакт! Сигурни ли сте, че искате да продължите?");
    var confirm = document.createElement('div');
    confirm.setAttribute('id', 'confirm');
    confirm.setAttribute('data-dismiss', 'modal');
    confirm.setAttribute('type', 'button');
    confirm.innerHTML = '<a class="text-decoration-none text-white" href="">Да</a>';
    confirm.classList.add('btn', 'btn-custom');
    confirm.querySelector('a').href = './deleteContact/' + valueId;
    node.appendChild(textnode);
    popup.querySelector("p[id='message']").appendChild(node);
    popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
    popup.classList.toggle("show");
    popup.style.display = "block";
    popup.querySelector("button[id='closePopup']").innerHTML = 'Не'
    popup.querySelector("button[id='closePopup']").parentNode.prepend(confirm);
}

function toggleConnection(valueId, connectionId) {
    var popup = document.getElementById("myPopup");
    var node = document.createElement("span");
    var textnode = document.createTextNode("Това ще разкачи избрания контакт контакт от избрания обект ! Сигурни ли сте, че искате да продължите?");
    var confirm = document.createElement('div');
    confirm.setAttribute('id', 'confirm');
    confirm.setAttribute('data-dismiss', 'modal');
    confirm.setAttribute('type', 'button');
    confirm.innerHTML = '<a class="text-decoration-none text-white" href="">Да</a>';
    confirm.classList.add('btn', 'btn-custom');
    confirm.querySelector('a').href = './toggleConnection/' + valueId + '-' + connectionId;
    node.appendChild(textnode);
    popup.querySelector("p[id='message']").appendChild(node);
    popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
    popup.classList.toggle("show");
    popup.style.display = "block";
    popup.querySelector("button[id='closePopup']").innerHTML = 'Не'
    popup.querySelector("button[id='closePopup']").parentNode.prepend(confirm);
}

function toggleConnectionOn(valueId, connectionId) {
    var popup = document.getElementById("myPopup");
    var node = document.createElement("span");
    var textnode = document.createTextNode("Това ще добави избрания контакт контакт към избрания обект ! Сигурни ли сте, че искате да продължите?");
    var confirm = document.createElement('div');
    confirm.setAttribute('id', 'confirm');
    confirm.setAttribute('data-dismiss', 'modal');
    confirm.setAttribute('type', 'button');
    confirm.innerHTML = '<a class="text-decoration-none text-white" href="">Да</a>';
    confirm.classList.add('btn', 'btn-custom');
    confirm.querySelector('a').href = './toggleConnection/' + valueId + '-' + connectionId;
    node.appendChild(textnode);
    popup.querySelector("p[id='message']").appendChild(node);
    popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
    popup.classList.toggle("show");
    popup.style.display = "block";
    popup.querySelector("button[id='closePopup']").innerHTML = 'Не'
    popup.querySelector("button[id='closePopup']").parentNode.prepend(confirm);
}

function deleteMessage(valueId) {
    var popup = document.getElementById("myPopup");
    var node = document.createElement("span");
    var textnode = document.createTextNode("Това ще изтрие избраното съобщеие! Сигурни ли сте, че искате да продължите?");
    var confirm = document.createElement('div');
    confirm.setAttribute('id', 'confirm');
    confirm.setAttribute('data-dismiss', 'modal');
    confirm.setAttribute('type', 'button');
    confirm.innerHTML = '<a class="text-decoration-none text-white" href="">Да</a>';
    confirm.classList.add('btn', 'btn-custom');
    confirm.querySelector('a').href = '/content/deleteMessage/' + valueId;
    node.appendChild(textnode);
    popup.querySelector("p[id='message']").appendChild(node);
    popup.querySelector("span").classList.add("text-danger", "text-center", "font-weight-bold");
    popup.classList.toggle("show");
    popup.style.display = "block";
    popup.querySelector("button[id='closePopup']").innerHTML = 'Не'
    popup.querySelector("button[id='closePopup']").parentNode.prepend(confirm);
}


function chooseType(element) {
    if (element.options.selectedIndex == 7) {
        var elementToClone = element.parentNode.parentNode.querySelector('input[name="socialURL[]"]');
        elementToClone.placeholder = 'Географска дължина';
        var enEl = element.parentNode.parentNode.querySelector('div[id=enEl]');
        enEl.classList.remove('d-none');
        enEl.querySelector('input[name="socialURLEN[]"]').disabled = false;
        enEl.querySelector('input[name="socialURLEN[]"]').placeholder = 'Географска ширина';
    } else if (element.options.selectedIndex == 1) {
        var elementToClone = element.parentNode.parentNode.querySelector('input[name="socialURL[]"]');
        elementToClone.placeholder = 'Адрес';
        var enEl = element.parentNode.parentNode.querySelector('div[id=enEl]');
        enEl.classList.remove('d-none');
        enEl.querySelector('input[name="socialURLEN[]"]').disabled = false;
        enEl.querySelector('input[name="socialURLEN[]"]').placeholder = 'Aдрес EN';
    } else {
        var elementToClone = element.parentNode.parentNode.querySelector('input[name="socialURL[]"]');
        elementToClone.placeholder = element.options[element.options.selectedIndex].text;
        var enEl = element.parentNode.parentNode.querySelector('div[id=enEl]');
        enEl.classList.add('d-none');
        enEl.querySelector('input[name="socialURLEN[]"]').disabled = true;
        enEl.querySelector('input[name="socialURLEN[]"]').placeholder = '';
    }
}

function createContactLoc(element, id) {
    var form = document.getElementById(id);
    form.classList.remove('d-none');
    deactivateButtons();
}